body {
  overflow-x: hidden;
  background: #ffffff;
  margin: 0;
  font-family: 'azo-sans-web', Arial, Helvetica, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.farm-container {
  margin: initial !important;
  margin-bottom: 48px !important;
}

a {
  color: rgb(141, 56, 56);
}

a:hover {
  color: #ffffff
}

a.covenants:hover {
  color: rgb(30, 103, 184);
}

.txt-grid {
  width: 100% !important;
}

footer {
  position: fixed;
  width: 100%;
}

/* #2fcf6d; */

.nav-link, .nav-link {
  color: #51473f;
}

.nav-link:focus, .nav-link:hover {
  color: rgba(229, 147, 16, 1);
}

.dropdown-item.active, .dropdown-item:active {
  background-color: rgba(229, 147, 16, 0.48);
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: #ffffff;
}

.mobile-nav-dropdown {
  box-sizing: border-box;
  color: #51473f;
  font-size: 24px;
  font-weight: 700;
  padding: 16px 24px;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.glow-btn {
  border: none !important;
  letter-spacing: 0 !important;
  min-width: 100px;
}

.glow-btn.green {
  background-color: #2fcf6d;
  box-shadow: 0px 0px 30px rgb(47 208 109 / 48%);
  color: #fff;
}

.glow-btn.green:disabled {
  background-color: rgba(47, 208, 109, 0.48);
  color: #fff;
  box-shadow: none;
}

.glow-btn.orange {
  background-color: rgba(229, 147, 16, 1);
  color: #fff;
  box-shadow: 0px 0px 30px rgb(229 147 16 / 48%);
}

.glow-btn.orange:disabled {
  background-color: rgba(229, 147, 16, 0.48);
  color: #fff;
  box-shadow: none;
}

.icon-wrap {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 18px;
}

.main-box-grid {
  box-shadow: -3px 7px 17px 4px #00000014;
  padding: 0 !important;
  border-radius: 12px;
}

.main-box-grid:first-child {
  /* margin-right: 48px !important; */
}

.main-box-grid .MuiContainer-maxWidthLg {
  padding: 0;
}

.main-box-grid {
  padding: 48px !important;
}

.main-box-grid button {
  text-transform: unset;
  margin-bottom: 24px;
}

.styled-token-input input {
  text-align: right;
  font-weight: 700;
}

.styled-token-input.bch input, .styled-token-input.bch input::placeholder {
  color: #2fcf6d;
  padding-right: 4px;
}

.styled-token-input.btc input, .styled-token-input.btc input::placeholder {
  color: rgba(229, 147, 16, 1);
  padding-right: 4px;
}

.styled-token-input.btc input,
.styled-token-input.bch input {
  border-radius: 9px;
  border: 1px solid #e9e9e9;
  background: #f3f3f3;
  padding: 0 16px;
}

/* .styled-token-input.btc {
  margin-bottom: 24px;
} */

/* .styled-token-input.bch {
  margin-bottom: 24px;
} */

.styled-token-wrapper span {
  color: #322015;
}

.styled-info {
  color: rgba(37, 37, 44, 0.48) !important;
}

.styled-info span {
  font-weight: 700;
}

.main-table-grid {
  box-shadow: -3px 7px 17px 4px #00000014;
  padding: 0 !important;
  border-radius: 12px;
}

.main-table-grid .makeStyles-container-2 {
  border: none;
}

/* !!! TODO: fix dat */

/* .makeStyles-container-2 > div {
max-height: 340px;
} */

/* .main-table-grid .MuiTableCell-stickyHeader {
  text-transform: uppercase;
  position: absolute;
  padding: 0px 48px !important;
  width: 33.3333%;
  height: 64px;
  display: flex;
  align-items: center;
} */

/* .main-table-grid .MuiTableRow-root .MuiTableCell-root:last-child {
  left: 42.3333%;
} */

.main-table-grid .MuiTypography-body1 {
  color: rgba(37, 37, 44, 0.48);
  letter-spacing: 1px;
  font-weight: 400;
}

.main-table-grid thead {
  position: relative;
}

.main-table-grid thead::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid rgba(37, 37, 44, 0.1);
  width: 100%;
  left: 0;
}

.custom-container {
  max-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

/* 
.custom-container.bch .main-table-grid .MuiTableRow-root .MuiTableCell-root div span:last-child {
  color: #2fcf6d;
  text-decoration: underline;
} */

/* .main-table-grid .MuiTableCell-root {
  border: none;
  padding: 0;
} */

/* .main-table-grid .MuiTable-root {
  padding: 0px 48px;
}

.main-table-grid .MuiTableRow-root {
  height: 64px;
} */

.reddish-icon {
  color: rgba(37, 37, 44, 0.48);
  margin: 0 16px;
}

.reddish-chip {
  padding: 5px 16px;
  border-radius: 999px;
  height: 32px !important;
}

.reddish-chip.green {
  border: 1px solid #2fcf6d;
  color: #2fcf6d;
}

.reddish-chip.orange {
  border: 1px solid rgba(229, 147, 16, 1);
  color: rgba(229, 147, 16, 1);
}

.makeStyles-container-3 {
  border: none !important;
}

.modal-child-wrap {
  padding: 48px !important;
}

.modal-wrap {
  max-width: 600px !important;
}

.modal-wrap button {
  text-transform: none;
}

.big-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 32px;
}

.modal-content {
  padding: 0 !important;
}

.burn-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  height: 64px;
}

.burn-item-content {
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 24px;
}

.burn-item-title {
  color: rgba(37, 37, 44, 0.48);
}

.burn-item-amount {
  margin-left: auto;
  font-weight: 700;
}

.burn-divider {
  height: 1px;
  width: 100%;
  background-color: rgba(37, 37, 44, 0.1);
  margin-bottom: 24px;
}

.davatar {
  margin-right: 10px;
}
.danger-label {
  display: flex;
  align-items: center;
}

.danger-label input {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.modal-btm {
  margin-top: 32px;
}

.modal-btm div {
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .main-table-grid, .main-box-grid {
    margin-top: 48px !important;
    margin: 0 48px !important;
  }
  .main-box-grid {
    margin-bottom: 48px !important;
  }
  .modal-wrap {
    max-width: unset !important;
  }
  .modal-child-wrap {
    width: unset !important;
    margin-left: -2px !important;
  }
  .main-box-grid {
    padding: 24px !important;
  }
  /* .main-table-grid .MuiTable-root,
  .main-table-grid .MuiTableCell-stickyHeader {
    padding: 0 24px !important;
  } */
  .main-box-grid:first-child, .MuiGrid-spacing-xs-2>.MuiGrid-item {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .modal-wrap button {
    padding: 0 16px;
  }
  .dropdown-menu.show {
    font-size: 20px;
  }
}

@media only screen and (max-width: 426px) {
  .main-head {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
  .main-head>div {
    display: none;
  }
  .main-head button:first-child {
    margin-bottom: 24px;
  }
  .page-header {
    padding-bottom: 24px !important;
  }
}

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}